.place-option {
  @apply !bg-transparent;
}

.place-option:hover {
  @apply !bg-neutral-200;
}

.place-option:last-of-type {
  position: relative;
  margin-bottom: 30px;
  overflow: visible!important;
}

.place-option:last-of-type::after {
  content: "";
  position: absolute;
  bottom: -25px;
  right: 10px;
  width: 144px;
  height: 18px;
  background: url("./logo/powered_by_google.png") no-repeat bottom right;
  cursor: default;
}
