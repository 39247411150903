.cky-consent-container.cky-box-bottom-left {
  bottom: 75px;
}
@media only screen and (max-width: 576px) {
  .cky-consent-container.cky-box-bottom-left {
    @apply w-full bottom-0;
  }
}

@media only screen and (max-width: 576px) {
  .cky-consent-container::before {
    content: "";
    width: 100dvw;
    height: 100dvh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .8);
    z-index: -1;
  }
}

.cky-btn-revisit-wrapper.cky-revisit-bottom-left {
  bottom: 55px;
  @apply !bg-primary;
}

.cky-consent-container .cky-consent-bar .cky-banner-btn-close {
  top: 15px;
  right: 15px;
}

.cky-btn-revisit-wrapper .cky-btn-revisit {
  @apply rounded-full;
}

.cky-consent-container, .cky-modal {
  @apply font-sans;
}

.cky-consent-container .cky-notice-des {
  @apply py-2;
}

.cky-consent-container .cky-notice-des * {
  @apply !text-sm !text-neutral-500;
}

.cky-consent-container .cky-btn, .cky-modal .cky-btn {
  border-radius: 2.5rem;
  font-size: 15px;
  font-weight: 500;
}

.cky-consent-container .cky-btn.cky-btn-customize {
  @apply !border-primary !text-primary;
}

.cky-consent-container .cky-btn.cky-btn-accept, .cky-modal .cky-btn.cky-btn-accept {
  @apply !bg-primary !border-primary;
}

.cky-modal .cky-switch input:checked {
  @apply !bg-primary;
}

.cky-consent-bar .cky-btn.cky-btn-reject {
  display: none!important;
}

.custom-cookiesyes-close-button {
  @apply font-body2 text-neutral-400 text-sm font-bold;
}