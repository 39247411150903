@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html, body, #root {
    padding: 0;
    margin: 0;
    font-feature-settings: "rlig" 1, "calt" 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: transparent;
  }

  iframe {
    outline: none!important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    text-align: center;
    -moz-appearance: textfield;
  }

  :root {
    --background: hsl(0 0% 100%);
    --foreground: hsl(222.2 47.4% 11.2%);
    --muted: hsl(210 40% 96.1%);
    --muted-foreground: hsl(215.4 16.3% 46.9%);
    --popover: hsl(0 0% 100%);
    --popover-foreground: hsl(222.2 47.4% 11.2%);
    --card: hsl(0 0% 100%);
    --card-foreground: hsl(222.2 47.4% 11.2%);
    --border: hsl(214.3 31.8% 91.4%);
    --input: hsl(214.3 31.8% 91.4%);
    --primary-foreground: hsl(210 40% 98%);
    --secondary-foreground: hsl(222.2 47.4% 11.2%);
    --accent: hsl(210 40% 96.1%);
    --accent-foreground: hsl(222.2 47.4% 11.2%);
    --destructive: hsl(0 100% 50%);
    --destructive-foreground: hsl(210 40% 98%);
    --ring: hsl(215 20.2% 65.1%);
    --radius: 0.5rem;
  }
  
  .dark {
    --background: hsl(224 71% 4%);
    --foreground: hsl(213 31% 91%);
    --muted: hsl(223 47% 11%);
    --muted-foreground: hsl(215.4 16.3% 56.9%);
    --popover: hsl(224 71% 4%);
    --popover-foreground: hsl(215 20.2% 65.1%);
    --card: hsl(224 71% 4%);
    --card-foreground: hsl(213 31% 91%);
    --border: hsl(216 34% 17%);
    --input: hsl(216 34% 17%);
    --primary-foreground: hsl(222.2 47.4% 1.2%);
    --secondary-foreground: hsl(210 40% 98%);
    --accent: hsl(216 34% 17%);
    --accent-foreground: hsl(210 40% 98%);
    --destructive: hsl(0 63% 31%);
    --destructive-foreground: hsl(210 40% 98%);
    --ring: hsl(216 34% 17%);
    --radius: 0.5rem;
  }

  /* fonts */

  @font-face {
    font-family: 'NexaBold';
    font-weight: 400;
    src: url('./assets/fonts/nexabold/nexabold-regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'NexaBold';
    font-weight: 700;
    src: url('./assets/fonts/nexabold/nexabold-bold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'OpenSans';
    font-weight: 300;
    src: url('./assets/fonts/opensans/opensans-light.ttf') format('truetype');
  }

  @font-face {
    font-family: 'OpenSans';
    font-weight: 400;
    src: url('./assets/fonts/opensans/opensans-regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'OpenSans';
    font-weight: 500;
    src: url('./assets/fonts/opensans/opensans-medium.ttf') format('truetype');
  }
}

.intercom-lightweight-app-launcher.intercom-launcher {
  bottom: 55px;
  right: 15px;
}

.intercom-launcher-frame {
  margin-top: -42px!important;
  margin-left: 5px!important;
}

.intercom-messenger-frame {
  bottom: 115px!important;
}